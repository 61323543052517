

require ('jquery.marquee');
require('./bootstrap');
require ('bootstrap');
require ('bootstrap-css');
require('print-this');
require('datatables.net');
require('datatables.net-dt');
// require ('excellentexport');
// require ('@linways/table-to-excel')


import jQuery from 'jquery';
window.$ = jQuery;
